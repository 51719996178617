/* Component Dependencies */
var destinationCrossLinksTemplate = require('templates/destinationCrossLinks.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'destinationCrossLinks',
  template: {
    'destinationCrossLinks': destinationCrossLinksTemplate
  },
  bindEvents: function() {
    this.panelHeading = this.$el.find('.panel-heading');
    this.windowWidth = window.innerWidth;
    $(window).on('load resize', this.resizeHandler.bind(this));
    this.$el.on('click', '.accordion-head',  this.listClickHandler.bind(this));
  },
  subscribe: {
    'TOGGLE_HOTEL_DIRECTORY_CROSS_LINKS': 'toggleHotelDirectoryCrossLink'
  },

  /**
   * This method is used handle the click event on the panel haeding and
   * to toggle the panel list items.
   * @return {Void}
   */
  listClickHandler: function _listClickHandler () {
    $panelIcon = this.panelHeading.find('.panel-icon'),
    isExpanded = $panelIcon.hasClass('icon-drawer-open');
    this.panelHeading.next('.panel-body').slideToggle('slow');
    $panelIcon.toggleClass('icon-drawer-open icon-drawer-close').end().find('.panel-title').attr('aria-expanded', isExpanded);
  },

  /**
   * This method is called on resizing the window and modifies the view of the cross-section
   * based on the screen size.
   * @return {Void}
   */
  resizeHandler: function _resizeHandler(event) {
    //This change has been added for iphone view. Scrolling was zooming the url address bar so resizing the screen.
    //Preventing false resize event like this.
    if(event.type === 'resize' && this.windowWidth === window.innerWidth)
      return true;
    if (window.innerWidth < 600) {
      this.panelHeading.find('.panel-title').removeClass('collapsed-heading').end().next('.panel-body').hide().end().find('.panel-icon').removeClass('icon-drawer-close').addClass('icon-drawer-open');
      this.panelHeading.addClass('accordion-head');
    } else {
      this.panelHeading.find('.panel-title').addClass('collapsed-heading').end().off('click').next('.panel-body').show();
      this.panelHeading.removeClass('accordion-head');
    }
  },
  toggleHotelDirectoryCrossLink: function _toggleHotelDirectoryCrossLink(isTileDisplayed){
    var _self=this;
    isTileDisplayed ? _self.$el.removeClass('l-display-none').addClass('l-display-block') : _self.$el.removeClass('l-display-block').addClass('l-display-none');
  }
});
